const maps = document.querySelectorAll('.interactive-map .clipped');
const akkordeonElems = document.querySelectorAll('#akkordeon-parent .accordion-header button');

if (akkordeonElems) {
    const domain = window.location.hostname.substring(0, window.location.hostname.indexOf('.'));
    let open = null;
    akkordeonElems.forEach((button, i) => {
        const buttonTarget = button.dataset.target;
        button.addEventListener('click', event => {
            maps.forEach((region) => {
                activateRegion(region, buttonTarget);
            });
        });
        if (domain.includes(button.dataset.domain_match)) {
            open = i;
        }
    });
    // Select tab for current domain.
    if (null === open) {
        open = 0;
    }
    akkordeonElems[open].dispatchEvent(new Event('click'));
}

if (maps) {
    maps.forEach(region => {
        const regionTarget = region.dataset.target;
        region.addEventListener('click', event => {
            akkordeonElems.forEach(header => {
                if (regionTarget === header.dataset.target) {
                    activateRegion(region, header.dataset.target);
                    header.dispatchEvent(new Event('click'));
                }
            });
        });
    });
}

function activateRegion(region, target) {
    if (region.dataset.target === target) {
        if (!region.classList.contains('active')) {
            region.classList.add('active');
        }
        const siblings = region.closest('.map').querySelectorAll('.clipped');
        siblings.forEach(sibling => {
            if (sibling !== region) {
                sibling.classList.remove('active');
            }
        });
    }
}
